<template>
  <div class="services-page">
    <b-row class="m-0 pt-1">
      <b-col class="pr-0 pl-0">
        <div class="d-flex w-100">
          <div ref="navToggle" class="nav-toggle nav-toggle-left py-2 mr-3 my-auto" @click="toggleNavMobile">
            <fa-icon class="toggle-button m-auto" icon="bars" size="lg" />
          </div>
          <div class="title py-2 pr-2 pl-3 my-auto">Services</div>
          <div ref="serviceButtonToggle" class="nav-toggle d-flex my-auto ml-auto" @click="toggleServiceButtonsMobile">
            <fa-icon class="toggle-button m-auto" icon="ellipsis-v" size="lg" />
          </div>
        </div>
      </b-col>
      <b-col cols="12" md="auto" class="d-flex flex-wrap align-items-center justify-content-end">
        <div v-if="selectedTab === 0" class="d-flex align-items-center justify-content-end flex-wrap w-auto py-2" :class="serviceButtonsClass">
          <b-button
            :class="[
              cancelServicesDisabled ? 'disabled-button-grey' : 'danger-button-outlined',
              'mr-1'
            ]"
            variant="primary-outline"
            aria-label="Cancel Services Button"
            :disabled="cancelServicesDisabled"
            @click="cancelServices"
          >
            Cancel Services
          </b-button>
          <b-button
            :class="[
              cancelServicesDisabled ? 'disabled-button-grey' : 'primary-button-outlined',
              'ml-2',
              'mr-2'
            ]"
            variant="primary-outline"
            aria-label="Manage Autopay Button"
            :disabled="manageAutopayDisabled"
            @click="manageAutopay(selectedIds)"
          >
            Manage Autopay
          </b-button>
          <div class="d-flex w-auto py-2" :class="serviceButtonsClass">
            <b-button
              :class="[
                !hasCompanies || loading ? 'disabled-button-grey' : 'primary-button',
                'ml-1',
                'mr-0'
              ]"
              style="margin-right: 2.5em"
              variant="primary"
              to="/hire-us"
              :disabled="!hasCompanies || loading"
              aria-label="Add Services Button"
            >
              Add Services
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <hr class="m-0">
    <div class="content-body">
      <div class="side-nav" :class="navClass">
        <button class="nav-button" :class="tabClass(0)" aria-label="My Services button" @click="tabChange(0)">
          <div class="d-flex flex-column align-items-center text-center m-auto pa-2">
            <fa-icon class="mt-1" icon="home" size="lg" />
            <span class="mt-1">My Services</span>
          </div>
        </button>
        <button class="nav-button" :class="tabClass(1)" aria-label="Shared with Me button" @click="tabChange(1)">
          <div class="shared d-flex flex-column align-items-center text-center m-auto pa-2">
            <fa-icon class="mt-1" icon="handshake" size="lg" />
            <div class="mt-1">Shared with Me</div>
          </div>
        </button>
      </div>
      <div class="services-content">
        <div class="scrollable-content">
          <div>
            <service-search-filters-form
              ref="service-filters"
              :search-scope="'search_company_service_names'"
              :sorted-descending="sortedDescending"
              :show-cancelled.sync="showCancelled"
              @update-sort-direction="updateSortedDescending"
              @refresh-companies="refresh"
            />
          </div>
          <div v-if="!loading">
            <div v-show="selectedTab === 0">
              <div v-for="(company, idx) in ownedCompanies" :key="company.id" class="mb-4">
                <company-services
                  :key="`${company.id}-services-${JSON.stringify(query)}`"
                  :ref="`${company.id}-services`"
                  v-model="expandedCompanies[idx]"
                  :company="company"
                  :show-cancelled="showCancelled"
                  :search-filter="searchFilter"
                  :wholesaler-filters="query"
                />
              </div>
              <div v-if="!ownedCompanies.length" class="text-center">
                No Companies Found
              </div>
            </div>
            <div v-show="selectedTab === 1">
              <div v-for="(company, idx) in sharedCompanies" :key="company.id" class="mb-4">
                <company-services
                  :key="`${company.id}-services-${JSON.stringify(query)}`"
                  :ref="`${company.id}-services`"
                  v-model="expandedShared[idx]"
                  :company="company"
                  :show-cancelled="showCancelled"
                  :search-filter="searchFilter"
                  :wholesaler-filters="query"
                />
              </div>
              <div v-if="!sharedCompanies.length" class="text-center">
                <h5>No Shared Companies Found</h5>
              </div>
            </div>
          </div>
          <ct-centered-spinner v-else />
        </div>
        <div class="pagination-wrapper" v-if="this.totalCompanies > 25">
          <b-pagination
            align="center"
            v-model="currentPage"
            :total-rows="this.totalCompanies"
            :per-page="this.limit"
            @input="pageChange"
          />
          <b-form-group>
            <b-form-select
              id="perPageSelect"
              v-model="limit"
              size="sm"
              class="my-0 ml-1"
              :options="pageOptions"
              @change="onLimitChange"
            />
          </b-form-group>
        </div>
      </div>
    </div>
    <cancellation-modal
      :bus="bus"
      @cancel-complete="cancelServicesComplete"
    />
    <rick-roll-modal :bus="bus" />
  </div>
</template>

<script>

import Vue from 'vue'
import {
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex'
import { companyServiceMixin } from '@/mixins/companyServiceMixin'
import { SET_SELECTED_SERVICES } from '@/store/mutations'
import { makeToastMixin } from '@/mixins/makeToastMixin'

const PAGE_OPTIONS = [
  { value: 25, text: '25 per page' },
  { value: 50, text: '50 per page' },
  { value: 100, text: '100 per page' },
  { value: 200, text: '200 per page' },
  { value: 500, text: '500 per page' },
]

export default {
  name: 'Services',
  components: {
    CompanyServices:   () => import('@/components/Services/CompanyServices/CompanyServices.vue'),
    FeatherIcon:       () => import('@/components/shared/FeatherIcon.vue'),
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner.vue'),
    CancellationModal: () => import('@/components/cancellation/CancellationModal'),
    RickRollModal:     () => import('@/components/cancellation/RickRollModal'),
    ServiceSearchFiltersForm: () => import('@/components/ServiceSearchFiltersForm.vue')
  },
  mixins: [companyServiceMixin, makeToastMixin],
  data() {
    return {
      bus: new Vue(),
      expandedCompanies: [],
      expandedShared: [],
      loading: false,
      ownedCompanies: [],
      showCancelled: false,
      searchFilter: null,
      searchName: null,
      selectedTab: 0,
      sharedCompanies: [],
      showNavMobile: false,
      showServiceButtons: false,
      sortOwnedDescending: false,
      sortSharedDescending: false,
      sortDown: true,
      query: {},
      wholesaleFilters: null,
      offset: 0,
      totalCompanies: 0,
      limit: 25,
      currentPage: 1,
    }
  },
  computed: {
    ...mapGetters('account', ['isAccountWholesaler', 'activeAccountServices', 'activeServicesCount']),
    ...mapGetters('services', ['selectedServices']),
    cancelServicesDisabled() {
      return !this.selectedServices.length || this.loading
    },
    hasCompanies() {
      return this.selectedTab === 0 ? this.ownedCompanies.length > 0 : this.sharedCompanies.length > 0
    },
    manageAutopayDisabled() {
      return !this.hasCompanies || this.loading || !this.selectedAutoPayables.length
    },
    navClass() {
      return this.showNavMobile ? null : 'hidden'
    },
    pageOptions() {
      return PAGE_OPTIONS
    },
    serviceButtonsClass() {
      return this.showServiceButtons ? 'display-buttons' : 'hide-buttons'
    },
    selectedAutoPayables() {
      return this.selectedServices
        .filter(sel => this.autopayPayableSelectable(sel.status))
        .filter(this.isSelectable || this.isDomainService)
        .map(svc => svc.id)
    },
    sortedDescending() {
      return this.selectedTab === 0 ? this.sortOwnedDescending : this.sortSharedDescending
    },
    selectedIds(){
      return this.selectedServices.map(svc => svc.id)
    },
  },
  async mounted() {
    this.loading = true
    this.setSelected([])
    await Promise.all([
      this.setActiveServicesCount(),
      this.processComplianceServices(this),
      this.loadIndexSimple(),
    ])
    this.loading = false
  },
  methods: {
    ...mapActions('account', [
      'setActiveServicesCount',
    ]),
    ...mapActions('services', [
      'companiesIndexSimple',
      'companiesSharedSimple',
      'processComplianceServices',
    ]),
    ...mapMutations('services', { setSelected: SET_SELECTED_SERVICES }),
    async cancelServices() {
      this.bus.$emit('showCancelServices', this.selectedServices)
    },
    async cancelServicesComplete() {
      const selectedCompanyIds = [...new Set(this.selectedServices.map(svc => svc.company_id))]
      selectedCompanyIds.forEach(cid => this.$refs[`${cid}-services`][0].loadServices())
      this.setSelected([])
      this.bus.$emit('showRickRoll')
      this.successToast('Success', 'Service cancellation request succeeded')
    },
    async loadIndexSimple() {
      this.loading = true
      const params = {
        order_by: 'name',
        order_direction: this.sortDown ? 'asc' : 'desc',
        offset: this.offset,
        limit: this.limit,
      }

      if (this.searchFilter) {
        params.search_name = this.searchName
        params.query = this.searchFilter
      }
      if (Object.keys(this.query).length > 0) {
        params.filter = Object.entries(this.query).map(([key, value]) => ({
          scope: key,
          value: value,
        }))
      } else {
        params.filter = []
        params.filter.push({ scope: "cancelled", value: this.showCancelled });
      }

      const data = await this.companiesIndexSimple(params)

      if(!data?.success) {
        this.errorToast('Error', 'Error loading companies')
        return
      }
      this.totalCompanies = data.result.total
      this.ownedCompanies = data.result.companies
      this.expandedCompanies = this.ownedCompanies.map(() => true)
      this.loading = false
    },
    async loadSharedSimple() {
      this.loading = true
      const params = {
        order_by: 'name',
        order_direction: this.sortDown ? 'asc' : 'desc',
        offset: this.offset,
        limit: this.limit,
      }

      if (Object.keys(this.query).length > 0) {
        params.filter = Object.entries(this.query).map(([key, value]) => ({
          scope: key,
          value: value,
        }))
      }

      if (this.searchFilter) {
        params.search_name = this.searchName
        params.query = this.searchFilter
      }

      const data = await this.companiesSharedSimple(params)

      if(!data?.success) {
        this.errorToast('Error', 'Error loading companies')
        return
      }

      this.totalCompanies = data.result.total
      this.sharedCompanies = data.result.companies
      this.expandedShared = this.sharedCompanies.map(() => true)
      this.loading = false
    },
    pageChange(newPage) {
      this.currentPage = newPage
      this.offset = (this.currentPage - 1) * this.limit
      if (this.selectedTab === 0) {
        this.loadIndexSimple()
      }
      else {
        this.loadSharedSimple()
      }
    },
    tabChange(value) {
      const serviceFilters = this.$refs['service-filters']
      serviceFilters.resetFilters()
      this.totalCompanies = 0
      this.query = { "cancelled": false }
      this.searchFilter = null
      this.selectedTab = value
      this.pageChange(1)
    },
    onLimitChange() {
      this.pageChange(1)
    },
    tabClass(number) {
      return number === this.selectedTab ? 'selected' : null
    },
    toggleNavMobile() {
      this.showNavMobile = !this.showNavMobile
    },
    toggleServiceButtonsMobile() {
      this.showServiceButtons = !this.showServiceButtons
    },
    updateSortedDescending() {
      this.sortDown = !this.sortDown
      this.pageChange(1)
    },
    filtersSubmit(newQuery) {
      // Filter out `undefined` values from `newQuery`
      const filteredQuery = Object.fromEntries(
        Object.entries(newQuery).filter(([_key, value]) => value !== undefined)
      )

      // Compare the current query with the new filtered query
      const isQueryChanged = JSON.stringify(this.query) !== JSON.stringify(filteredQuery)

      if (isQueryChanged) {
        if (Object.keys(filteredQuery).length > 0) {
          // Filters are applied
          this.searchName = 'search_filtered_wholesaler'
          this.query = filteredQuery
        } else {
          // No filters applied
          this.searchName = ''
          this.query = {}
        }
      }
    },
    refresh(filters, searchBar) {
      this.selectedTab === 0 ? this.ownedCompanies = [] : this.sharedCompanies = []
      this.filtersSubmit(filters)

      if (searchBar) {
        this.searchFilter = searchBar.query
        this.searchName = searchBar.search_name
      }

      this.pageChange(1)
    },
  },
}
</script>

<style lang="scss" scoped>

.services-page {
  min-width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: -20px -15px;
  display: flex;
  flex-direction: column;

  .hover-pointer {
    :hover {
      cursor: pointer;
    }
  }

  .nav-toggle {
    display: none;
    * {
      display: none;
    }
  }

  .primary-button {
    background-color: #000864;
    border-color: #000864 !important;
    color: white;
    border-radius: 4px;

    &:not(:disabled):hover {
      background-color: #3F458A !important;
    }

    &-outlined {
      color: #000864 !important;
      border-color: #000864 !important;
      border-radius: 4px !important;
      background-color: white !important;
      height: 40px;

      &:not(:disabled):hover {
        background-color: #ECECFA !important;
      }
    }
  }
  button {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .danger-button-outlined {
    color: #BC2F2F !important;
    border-color: #BC2F2F !important;
    border-radius: 4px !important;
    height: 40px;

    &:not(:disabled):hover {
      background-color: #FCF0F0 !important;
    }
  }

  .disabled-button-grey {
    background-color: #e8e4e4;
    border-color: transparent;
    border-radius: 4px;
    color: #939292 !important;
    cursor: not-allowed;
  }

  .title {
    font-size: 2rem;
    font-weight: bold;
  }

  hr {
    border-top: 1px solid #BDBDBD;
    margin: 0;
  }

  .content-body {
    flex: 1;
    display: flex;

    .side-nav {
      background-color: #F8F8F8;
      min-height: 100%;
      min-width: 125px;
      width: 125px;
      margin-bottom: .25rem;

      .nav-button {
        display: flex;
        width: 100%;
        height: 100px;
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        outline: none;

        .shared {
          padding: 0 1.5rem;
        }

        &:hover {
          cursor: pointer;
        }

        &.selected {
          background-color: #EAE9FC;
        }
      }

      @media (min-width: 768px) {
        * {
          display: flex !important;
        }
      }
    }

    .scrollable-content {
      flex: 1;
      overflow-y: auto;
      height: 80vh;
      padding: 15px;
    }

    .pagination-wrapper {
      position: sticky;
      align-items: center;
      bottom: 0;
      border-top: 1px solid #C0C0C0;
      background-color: white;
      padding: 10px 0;
      z-index: 1000;
      display: flex;
      justify-content: center;
    }

    .services-content {
      min-height: 100%;
      flex: 1;

      .search-container {
        min-width: 250px;
        .form-control {
          min-height: 40px !important;
          flex: 1;
          border-right: none;
        }

        .input-group-text {
          height: 40px !important;
          border-left: none;
          border-radius: 0 4px 4px 0;
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .primary-button, .primary-button-outlined, .danger-button-outlined {
      flex-grow: 1;
      height: auto;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }

    .nav-toggle {
      display: flex;
      border: 1px solid #BDBDBD;
      border-radius: 8px 0 0 8px;
      cursor: pointer;
      width: 50px;
      height: 50px;
      * {
        display: block;
      }
    }

    .nav-toggle-left {
      border-radius: 0 8px 8px 0;
    }

    .d-flex {
      flex-direction: row !important;
    }

    .display-buttons {
      margin: 0 auto !important;
    }

    .hide-buttons {
      display: none !important;
      * {
        display: none !important;
      }
    }

    .content-body {
      flex-direction: column;

      .side-nav {
        min-height: 0;
        width: 100%;
        display: flex;
        flex-direction: row;

        .nav-button {
          flex: 1;
          height: 75px;
          padding: 0;
          svg {
            margin-right: 0.5rem;
          }
          .shared {
            padding: 0;
          }
        }

        &.hidden {
          display: none;
          * {
            display: none;
          }
        }
      }
      .services-content {
        min-height: 100%;
        flex: 1;

        .search-container {
          .form-control {
            min-width: 0;
          }

          .input-group-text {
            min-width: 0;
          }
        }
      }
    }
  }
}
</style>
